import { urls } from 'config';
import { createAction } from 'redux-actions';
import { commonGet } from 'modules/api';
import { createCommonItemConstants, createCommonItemReducer } from './utils';
import { getSatProducts, stopPollingSatProducts } from './satProductsChecking';

const satProductsByFieldConstant = createCommonItemConstants(
  'SAT_PRODUCTS_BY_FIELD'
);

// reducer
export default createCommonItemReducer(satProductsByFieldConstant);

const setIsPending = createAction(satProductsByFieldConstant.SET_IS_PENDING);
const setItem = createAction(satProductsByFieldConstant.SET_ITEM);

// actions

export const setSatProductsByDivisionIsPending = (value) => setIsPending(value);
export const stopPollingSatProductsByField = () =>
  stopPollingSatProducts(setIsPending);

export const getSatProductsByField = (fieldId, activeScene, intervalResult) =>
  getSatProducts(
    `${urls().satFields}/${fieldId}/products/${activeScene?.source_type}/${
      activeScene?.date
    }`,
    setItem,
    setIsPending,
    intervalResult
  );

export const getReliefByField = (fieldId) => {
  const url = `${urls().satFieldRelief}/${fieldId}`;
  return commonGet({
    url,
  });
};
