import React, {
  memo,
  useCallback,
  useState,
  useEffect,
  createElement,
  useMemo,
  useRef,
} from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import InputMask from 'react-input-mask';
import { compose } from 'utils';
import Loader from 'components/common/Loader';
import { keyCodes } from './helpers';

const ClearIcon = ({ onClick = () => {} }) => {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      onClick={onClick}
      className="input-ui__clear-icon"
    >
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
    </svg>
  );
};

const InputUi = ({
  id,
  label = '',
  value,
  isNumber = false,
  isFloat = false,
  isPositive = false,
  isInvalid = false,
  required,
  disabled,
  min,
  isClearable,
  max,
  error,
  prepend,
  showError = true,
  autoComplete = 'on',
  classNameWrap,
  classNameLabel,
  classNameInput,
  classNameErrorEmpty,
  classNameError,
  classNamePrepend,
  classNameInputWrap,
  onChange = () => {},
  onKeyDown = () => {},
  onBlur = () => {},
  onFocus = () => {},
  loading = false,
  as = 'input',
  resize = 'none',
  autosize = false,
  mask,
  alwaysShowMask,
  beforeMaskedStateChange,
  isShowErrorEmpty = false,
  testId,
  ...props
}) => {
  const inputRef = useRef(null);
  const selectedCursorPosition = useRef(null);
  if (mask && (isNumber || isFloat)) {
    throw new Error(
      'Properties "mask" and "isNumber/isFloat" should not be used together'
    );
  }
  if (autosize && as !== 'textarea') {
    throw new Error('Before use "autosize" set property "textarea"');
  }
  if (isNumber && isFloat) {
    throw new Error(
      'Properties "isNumber" and "isFloat" should not be used together'
    );
  }
  const [inputId] = useState(id || uuid());
  const [focused, setFocused] = useState(false);
  const [localValue, setLocalValue] = useState(value ? `${value}` : '');
  const checkMin = useCallback(
    (val) => (min !== undefined && val < min ? min : val),
    [min]
  );
  const checkMax = useCallback(
    (val) => {
      if (max !== undefined) {
        if (isFloat || isNumber) {
          return val > max ? max : val;
        }
        return val.length <= max ? val : `${val}`.substr(0, max);
      }
      return val;
    },
    [max, isFloat, isNumber]
  );
  const checkMinMax = useCallback((val) => compose(checkMax, checkMin)(val), [
    checkMax,
    checkMin,
  ]);

  const getCheckedValue = useCallback(
    (val) => {
      if (!val && val !== 0) {
        return isNumber || isFloat ? undefined : val === null ? val : '';
      }
      if (isPositive && (isNumber || isFloat) && val < 0) return undefined;
      return checkMinMax(val);
    },
    [isNumber, isFloat, isPositive, checkMinMax]
  );

  const handleBlur = useCallback(
    (evt) => {
      setFocused(false);
      onBlur(evt);
      if (isFloat && localValue) {
        const parsedValue = parseFloat(localValue);
        setLocalValue(!Number.isNaN(parsedValue) ? `${parsedValue}` : '');
      }
    },
    [isFloat, localValue, onBlur]
  );

  const handleFocus = useCallback(
    (evt) => {
      setFocused(true);
      onFocus(evt);
    },
    [onFocus]
  );

  const handleChangeFloat = useCallback(
    (evt) => {
      const val = `${checkMinMax((evt.target.value || '').replace(/,/g, '.'))}`;
      const parsedValue = parseFloat(val);
      const re = /^\d+(\.(\d+)?)/;
      if (re.test(val)) {
        setLocalValue(val.match(re)?.[0] || '');
      } else if (
        !isPositive &&
        (val === '-' || /[-]\d+$|[-]\d+\.$/.test(val))
      ) {
        setLocalValue(`${val}`);
      } else {
        setLocalValue(!Number.isNaN(parsedValue) ? `${parsedValue}` : '');
      }
      onChange(!Number.isNaN(parsedValue) ? parsedValue : null, evt);
    },
    [onChange, isPositive, checkMinMax]
  );

  const handleChangeNumber = useCallback(
    (evt) => {
      const val = `${checkMinMax(evt.target.value)}`;
      const parsedValue = parseInt(val, 10);
      if (!isPositive && (val === '-' || /[-]\d+$/.test(val))) {
        setLocalValue(`${val}`);
      } else {
        setLocalValue(!Number.isNaN(parsedValue) ? `${parsedValue}` : '');
      }
      onChange(!Number.isNaN(parsedValue) ? parsedValue : null, evt);
    },
    [onChange, isPositive, checkMinMax]
  );

  const handleChangeText = useCallback(
    (evt) => {
      const val = `${checkMinMax(evt.target.value)}`;
      setLocalValue(val);
      onChange(val, evt);
    },
    [onChange, checkMinMax]
  );

  const handleChange = useCallback(
    (evt) => {
      selectedCursorPosition.current = inputRef.current?.selectionStart ?? null;
      if (isNumber) {
        return handleChangeNumber(evt);
      }
      if (isFloat) {
        return handleChangeFloat(evt);
      }
      return handleChangeText(evt);
    },
    [isNumber, isFloat, handleChangeText, handleChangeFloat, handleChangeNumber]
  );

  const handleKeyDown = useCallback(
    (evt) => {
      const val = evt.target.value;
      onKeyDown(evt);
      if (isNumber || isFloat) {
        const keyCode = evt.keyCode || evt.which;
        if (
          keyCode &&
          [keyCodes.ARROW_UP, keyCodes.ARROW_DOWN].includes(keyCode)
        ) {
          const decimalAfterPoint = val?.split('.')?.[1]?.length || 0;
          const incremented = (
            +val + (keyCode === keyCodes.ARROW_UP ? 1 : -1)
          ).toFixed(decimalAfterPoint);
          const newValue = checkMinMax(
            isFloat ? parseFloat(incremented) : parseInt(incremented, 10)
          );
          if (newValue > 0 || !isPositive) {
            setLocalValue(!Number.isNaN(newValue) ? `${newValue}` : '');
            onChange(!Number.isNaN(newValue) ? newValue : undefined, evt);
          }
        }
      }
    },
    [isNumber, isFloat, isPositive, onKeyDown, onChange, checkMinMax]
  );

  const inputAs = useMemo(() => {
    if (as === 'textarea') {
      if (autosize) {
        return TextareaAutosize;
      }
      return 'textarea';
    }
    if (mask) {
      return InputMask;
    }
    return 'input';
  }, [as, autosize, mask]);

  const InputElement = createElement(inputAs, {
    'data-testid': testId || 'input',
    id: inputId,
    value: localValue,
    ref: inputRef,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onBlur: handleBlur,
    onFocus: handleFocus,
    className: cn(
      'input-ui__input',
      { 'input-ui__textarea': as === 'textarea' },
      classNameInput
    ),
    style: as === 'textarea' && resize ? { resize } : undefined,
    autoComplete,
    disabled,
    mask,
    ...(mask ? { alwaysShowMask, beforeMaskedStateChange } : {}),
    ...props,
  });

  useEffect(() => {
    const checkedValue = getCheckedValue(value);
    // if (checkedValue !== value && value !== undefined) {
    //   console.log('oncahnge');
    //   onChange(checkedValue);
    // }
    if (
      (isFloat && parseFloat(value) !== parseFloat(localValue)) ||
      (isNumber && parseInt(value, 10) !== parseInt(localValue, 10))
    ) {
      const val = isFloat
        ? parseFloat(checkedValue)
        : parseInt(checkedValue, 10);
      setLocalValue(!Number.isNaN(val) ? `${val}` : '');
    } else if (!isNumber && !isFloat && checkedValue !== localValue) {
      setLocalValue(checkedValue ? `${checkedValue}` : '');
    } else if (
      checkedValue !== localValue &&
      checkedValue === value &&
      isNumber
    ) {
      setLocalValue(`${checkedValue}`);
    }
    if (selectedCursorPosition.current) {
      inputRef.current?.setSelectionRange?.(
        selectedCursorPosition.current,
        selectedCursorPosition.current
      );
    }
  }, [
    value,
    isFloat,
    localValue,
    isNumber,
    getCheckedValue,
    isPositive,
    onChange,
  ]);

  const handleClear = useCallback(() => {
    setLocalValue('');
    onChange('');
  }, [onChange]);

  return (
    <div className={cn('input-ui__wrap', classNameWrap)}>
      {!!label && (
        <div
          className={cn(
            'input-ui__label',
            { 'input-ui__label_required': required },
            classNameLabel
          )}
          data-testid="input-label"
        >
          {label}
        </div>
      )}
      <label
        data-testid="input-label-for"
        htmlFor={inputId}
        className={cn(
          'input-ui__input-wrap',
          {
            'input-ui__input-wrap_invalid': isInvalid,
            'input-ui__input-wrap_focused': focused,
            'input-ui__input-wrap_disabled': disabled,
          },
          classNameInputWrap
        )}
      >
        {!!prepend && (
          <div
            className={cn('input-ui__prepend', classNamePrepend)}
            data-testid="input-prepend"
          >
            {prepend}
          </div>
        )}
        <>
          {loading && <Loader className="input-ui__loader" />}
          {InputElement}
          {isClearable && localValue && <ClearIcon onClick={handleClear} />}
        </>
      </label>
      {showError &&
        (isInvalid && error ? (
          <span
            data-testid="input-error"
            className={cn('input-ui__error', classNameError)}
          >
            {error}
          </span>
        ) : (
          isShowErrorEmpty && (
            <div
              data-testid="input-error-empty"
              className={cn('input-ui__error-empty', classNameErrorEmpty)}
            />
          )
        ))}
    </div>
  );
};

InputUi.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isNumber: PropTypes.bool,
  isFloat: PropTypes.bool,
  isPositive: PropTypes.bool,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  error: PropTypes.string,
  prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showError: PropTypes.bool,
  autoComplete: PropTypes.string,
  classNameWrap: PropTypes.string,
  classNameLabel: PropTypes.string,
  classNameInput: PropTypes.string,
  classNameErrorEmpty: PropTypes.string,
  classNameError: PropTypes.string,
  classNamePrepend: PropTypes.string,
  classNameInputWrap: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  loading: PropTypes.bool,
  as: PropTypes.oneOf(['textarea', 'input']),
  resize: PropTypes.string,
  autosize: PropTypes.bool,
  mask: PropTypes.string,
  testId: PropTypes.string,
  alwaysShowMask: PropTypes.bool,
  beforeMaskedStateChange: PropTypes.func,
  isShowErrorEmpty: PropTypes.bool,
};

export default memo(InputUi);
